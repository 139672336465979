<template>
  <div class="v-stack h-stretch gap-3" v-if="user">
    <div class="card light mn">
      <div class="content gap-3">
        <label class="text-right">Pay: </label>
        <div class="text-left">
          {{ currentPay }} Kč/hod ({{
            Math.round((currentPay * 8 * 252) / 12 / 1000) * 1000
          }}
          Kč/měsíc)
        </div>
      </div>
    </div>
    <a class="heading-title-2">Pay</a>
    <div class="table">
      <div class="table-header pay-row">
        <div class="text-left">Date</div>
        <div class="text-left">Created By</div>
        <div class="text-left">Amount</div>
      </div>
      <div v-for="pay in user.pay" :key="pay._id" class="table-row pay-row">
        <div class="text-left">
          {{ formatDate(pay.date, "DD.MM.YYYY") }}
        </div>
        <div class="text-left">
          {{ pay.createdBy ? pay.createdBy.fullName : "" }}
        </div>
        <div class="text-left">
          {{ Math.round(pay.amount) }} Kč/hod ({{
            Math.round((pay.amount * 8 * 252) / 12 / 1000) * 1000
          }}
          Kč/měsíc)
        </div>
      </div>
      <div v-if="user.pay.length == 0" class="table-row">
        <div>No pay set</div>
      </div>
    </div>
    <div class="h-stack h-end">
      <button
        v-if="$store.state.accessLevel > 9"
        class="add"
        @click="$router.push('/users/pay/new/' + (id || $store.state.id))"
      >
        Add
      </button>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
const moment = require("moment");

export default {
  props: ["id"],
  data() {
    return {
      user: null,
    };
  },
  computed: {
    currentPay() {
      let value = 0;

      const date = moment();
      let currentPay = null;
      for (const pay of this.user.pay) {
        const payDate = moment(pay.date);
        if (payDate.isSameOrBefore(date, "day")) {
          if (currentPay == null) {
            currentPay = pay;
          } else {
            const currentDate = moment(currentPay.date);
            if (currentDate.isBefore(payDate)) {
              currentPay = pay;
            }
          }
        }
      }

      if (currentPay) {
        value = Math.round(currentPay.amount);
      }

      return value;
    },
  },
  methods: {
    ...mapActions(["getUser"]),
    refresh() {
      this.getUser(this.id || this.$store.state.id)
        .then((user) => {
          this.user = user;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    formatDate(dateString, format) {
      const date = moment(dateString);
      return date.format(format);
    },
  },
  mounted() {
    this.refresh();
  },
};
</script>

<style scoped>
label,
input {
  align-self: center;
}
</style>

<style lang="scss" scoped>
.pay-row {
  grid-auto-columns: 1fr 1fr !important;
  gap: 10px;
}
</style>
